<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.add') }} {{ $t('app.jugadores.single') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="observer"
          v-slot="{ validate, reset }"
        >
          <form>
            <v-list
              three-line
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.usuarios.single') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.usuarios.single')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.persona_id"
                        :placeholder="$t('app.usuarios.single')"
                        :items="usuarios"
                        item-text="persona.fullname"
                        item-value="id"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.responsible_name') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.responsible_name')"
                      :rules="`${ responsableIsRequired ? 'required' : '' }`"
                    >
                      <v-text-field
                        v-model="item.nombre_persona_responsable"
                        :placeholder="$t('app.headers.responsible_name')"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-row>
                <v-col
                  sm="6"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('app.headers.height') }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.headers.height')"
                          rules=""
                        >
                          <v-text-field
                            v-model="item.estatura"
                            :placeholder="$t('app.headers.height')"
                            :error-messages="errors"
                            suffix="cm"
                            type="number"
                          />
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col
                  sm="6"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('app.headers.weight') }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.headers.weight')"
                          rules=""
                        >
                          <v-text-field
                            v-model="item.peso"
                            :placeholder="$t('app.headers.weight')"
                            :error-messages="errors"
                            type="number"
                            suffix="kg"
                          />
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('app.headers.photo') }}
                    <small class="float-right">
                      {{ $t('app.general.photo_dimensions') }}
                    </small>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <base-file-input
                      :placeholder="$t('app.headers.photo')"
                      icon="mdi-image"
                      accept="image/*"
                      @change="onChangeControl"
                    />
                    <br>
                    <base-video-record-input @on-confirm="savePhotoTaken" />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.id_type') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.id_type')"
                      rules="required"
                    >
                      <v-select
                        v-model="item.tipo_identificacion"
                        :placeholder="$t('app.headers.id_type')"
                        :items="tipoIdentificacion"
                        item-text="nombre"
                        item-value="id"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.id_number') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.id_number')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.numero_identificacion"
                        :placeholder="$t('app.headers.id_number')"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.id_scans') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <base-file-input
                      :placeholder="$t('app.headers.id_scans')"
                      icon="mdi-image"
                      accept="image/*"
                      multiple
                      @change="onChangeControlScans"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.id_verified') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-switch
                      v-model="item.identificacion_verificada"
                      class="pl-5"
                      :placeholder="$t('app.headers.id_verified')"
                      :label="item.identificacion_verificada ? $t('app.general.yes') : $t('app.general.no')"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from 'vuex'

  export default {
    name: 'JugadoresAdd',
    data () {
      return {
        dialog: false,
        item: {
          persona_id: null,
          peso: null,
          estatura: null,
          tipo_identificacion: null,
          numero_identificacion: null,
          identificacion_verificada: false,
          nombre_persona_responsable: '',
          identificacion_scans: [],
          foto: null,
          fotoBlob: null,
          equipo_id: null,
          posicion_id: null,
          capitan: false,
        },
        usuarios: [],
        equipos: [],
        posiciones: [],
        tipoIdentificacion: [],
      }
    },
    computed: {
      ...mapState([
        'appData',
      ]),
      responsableIsRequired () {
        let currentUserSelected = false
        if (this.item.persona_id) {
          const user = this.usuarios.filter(user => user.id === this.item.persona_id)
          if (user.length > 0) {
            currentUserSelected = user[0]
          }
        }

        if (currentUserSelected && currentUserSelected.hasOwnProperty('persona') && currentUserSelected.persona.hasOwnProperty('fecha_nacimiento')) {
          const control = this.$moment().subtract(18, 'years')
          const borndate = this.$moment(currentUserSelected.persona.fecha_nacimiento, 'YYYY-MM-DD')

          if (borndate.isSameOrBefore(control)) {
            return false
          }
        }

        return true
      },
    },
    mounted () {
      EventBus.$on('jugadores-add', async (user) => {
        this.toggleLoader()
        this.usuarios = this.appData.usuarios
        this.equipos = this.appData.equipos
        this.posiciones = this.appData.posiciones
        this.tipoIdentificacion = this.appData.tipoIdentificacion

        if (user != undefined) {
          this.item.persona_id = user.persona.id
        }

        this.usuarios = this.usuarios.filter(item => {
          const control = item.roles.filter(rol => rol.slug === 'player')

          if (control.length > 0) { return false } else { return true }
        })

        this.toggleLoader()
        this.dialog = true
      })
    },
    methods: {
      onChangeControl (files) {
        if (files && files.length > 0) {
          this.item.foto = files[0]
        }
      },
      onChangeControlScans (files) {
        if (files && files.length > 0) {
          this.item.identificacion_scans = files
        }
      },
      async saveItem () {
        this.$refs.observer.validate()
          .then(async success => {
            if (!success) return

            this.toggleLoader()

            const form = new FormData()
            form.append('persona_id', this.item.persona_id)
            if (this.item.peso) {
              form.append('peso', this.item.peso)
            }
            if (this.item.estatura) {
              form.append('estatura', this.item.estatura)
            }
            form.append('tipo_identificacion', this.item.tipo_identificacion)
            form.append('numero_identificacion', this.item.numero_identificacion)
            form.append('identificacion_verificada', this.item.identificacion_verificada)
            form.append('nombre_persona_responsable', this.item.nombre_persona_responsable)

            if (this.item.fotoBlob) {
              form.append('foto', this.item.fotoBlob, 'foto.png')
            }
            else {
              if (this.item.foto) form.append('foto', this.item.foto)
            }

            if (this.item.identificacion_scans.length > 0) {
              for (const scan of this.item.identificacion_scans) {
                form.append('identificacion_scans[]', scan)
              }
            }

            await this.$http.post(route('v1/player'), form, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
              .then(response => {
                if (response.body.code === 200) {
                  this.item = {
                    persona_id: null,
                    peso: null,
                    estatura: null,
                    tipo_identificacion: null,
                    numero_identificacion: null,
                    identificacion_verificada: false,
                    nombre_persona_responsable: '',
                    foto: null,
                    equipo_id: null,
                    posicion_id: null,
                    capitan: false,
                    identificacion_scans: [],
                  }

                  requestAnimationFrame(() => {
                    this.$refs.observer.reset()
                  })

                  this.dialog = false

                  let jugador = false
                  if (response.body.data.length > 0) jugador = response.body.data[0]

                  EventBus.$emit('jugadores-add-to-team-question', {
                    jugador: jugador,
                  })
                } else {
                  this.processError(response)
                }
              }, error => {
                this.processError(error)
              })

            this.toggleLoader()
          })
      },
      savePhotoTaken (photo) {
        this.item.fotoBlob = photo
      },
    },
  }
</script>

<style scoped>

</style>
